import biLogger from '@wix/web-bi-logger';
import { CommonConfig, CommonConfigApi, Logger } from '@wix/web-bi-logger/dist/src/types';

export const hfBiConfig = {
  endpoint: 'hp',
  src: '19',
  clickOnHeader: '311',
  clickOnFooter: '312',
  signinClick: '314',
  hoverOnHeader: '347',
  hoverOnFooter: '348',
};

const commonConfig: CommonConfig = {
  brand: 'editorx',
};

const commonConfigGetter: CommonConfigApi = {
  get(key: keyof CommonConfig): string {
    const value = commonConfig[key];
    return typeof value === 'string' ? value : '';
  },
  getAll(): CommonConfig {
    return commonConfig;
  },
};

export const wrapLinkWithBiEvent = (
  logger: Logger,
  link: HTMLAnchorElement,
  evid: string,
  extendParams: (link: HTMLAnchorElement) => { [key: string]: string },
) => {
  const listener = (event: Event) => {
    event.preventDefault();
    let params = { evid };

    if (extendParams) {
      params = { ...params, ...extendParams(link) };
    }

    const referencedWindow = link.target === '_blank' ? window.open() : window;
    logger.log(params).then(() => {
      if (link.href) {
        referencedWindow && referencedWindow.location.assign(link.href);
      }
    });
  };
  link.addEventListener('click', listener);
  link.addEventListener('tap', listener);
};

export const logMenuToggle = (isExpanded: boolean) => {
  headerAndFooterBiLogger.log({
    evid: hfBiConfig.clickOnHeader,
    origin: window.location.href,
    url: '',
    row: 'first',
    tab: isExpanded ? 'open_menu_header' : 'close_menu_header',
    version: window.EDITOR_X_HF_VERSION,
  });
};

export const headerAndFooterBiLogger = biLogger
  .factory({ endpoint: hfBiConfig.endpoint })
  .updateDefaults({ src: hfBiConfig.src })
  .withCommonConfigGetter(() => commonConfigGetter)
  .logger();
