import { Logo } from '../Logo/Logo';
import s from './Header.scss';
import { Navigation } from './Navigation/Navigation';
import { Translator } from '../../modules/translate';
import { HeaderStructure } from '../../data/types';
import { Config } from '../../modules/config';
import { DataHook } from '../../modules/dataHooks';

interface HeaderProps {
  t: Translator;
  structure: HeaderStructure;
  config: Config;
}

const Header = ({ t, structure: { nav }, config }: HeaderProps) => {
  return `
    <header class="${s.container}" id="EDITOR_X_HEADER">
      <style>
        #EDITOR_X_HEADER {
          visibility: hidden;
          height: 50px;
        }
        @media (min-width: 751px) {
          #EDITOR_X_HEADER {
            height: 72px;
          }
        }
      </style>
      <div class="${s.header}">
        ${Logo({ dataHook: DataHook.Logo, t, language: config.getContentLanguage() })}
        ${Navigation({ navigation: nav, t })}
      </div>
    </header>
  `;
};

export default Header;
