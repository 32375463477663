import s from './Copyright.scss';

export interface CopyrightProps {
  t(key: string): string;
}

export const Copyright = ({ t }: CopyrightProps) => {
  const currentYear = new Date().getFullYear();
  return `
    <div class="${s.copyright}">
        ${t('footer_copyright')} © 2006-${currentYear}
    </div>
  `;
};
