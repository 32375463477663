import { createTranslator, Messages, Translator } from './translate';
import { Config } from './config';
import { Experiments } from './experiments';
import { StructureBuilder } from '../data/StructureBuilder';
import { FooterStructure, HeaderStructure } from '../data/types';
import { $, $$, getFooter, getHeader, getLanguagePickerButton, getNavigationMenu } from './selectors';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { headerAndFooterBiLogger, hfBiConfig, logMenuToggle, wrapLinkWithBiEvent } from './bi';
import headerNavCSS from '../components/Header/Navigation/Navigation.scss';
import { StartNow } from '../components/Header/Navigation/StartNow';
import { UserMenu } from '../components/Header/Navigation/UserMenu';
import footerMenuCSS from '../components/Footer/Menu/Menu.scss';
import debounce from 'lodash.debounce';
import logoCSS from '../components/Logo/Logo.scss';
import headerCSS from '../components/Header/Header.scss';
import { LanguagePicker } from '../components/Header/LanguagePicker/LanguagePicker';
import { SupportedLanguage, supportedLanguages } from '../data/languages';
import { DataFetcher, UserProfileData } from './DataFetcher';
import { DataHook } from './dataHooks';

interface HeaderFooterState {
  isDesktop: boolean;
}

interface HeaderFooterControllerParams {
  messages: Messages;
  config: Config;
  experiments: Experiments;
  dataFetcher: DataFetcher;
}

export class HeaderFooterController {
  private readonly MOBILE_HEADER_MAX_WIDTH = 1024;
  private readonly t: Translator;
  private readonly config: Config;
  private readonly experiments: Experiments;
  private readonly headerStructure: HeaderStructure;
  private readonly footerStructure: FooterStructure;
  private readonly dataFetcher: DataFetcher;
  private state: HeaderFooterState;

  constructor({ messages, config, experiments, dataFetcher }: HeaderFooterControllerParams) {
    this.config = config;
    this.dataFetcher = dataFetcher;
    this.t = createTranslator(messages);
    this.experiments = experiments;
    this.state = this._updateState();
    const builder = new StructureBuilder();

    this.headerStructure = builder.getHeaderStructureByLanguage(
      this.config.getContentLanguage(),
      this.config.getPageLanguage(),
      this._getRedirectToLinks(),
    );
    this.footerStructure = builder.getFooterStructureByLanguage(this.config.getContentLanguage());
  }

  private _updateState(): HeaderFooterState {
    return {
      isDesktop: window.innerWidth > this.MOBILE_HEADER_MAX_WIDTH,
    };
  }

  public init() {
    if (!this.config.isHeaderUsed() && !this.config.isFooterUsed()) {
      console.warn('HTMLs of editorx header and footer are missed in the page!!!');
      return;
    }

    if (!getHeader() && this.config.isHeaderUsed()) {
      this._insertHeaderHtml();
    }

    if (!getFooter() && this.config.isFooterUsed()) {
      this._insertFooterHtml();
    }

    if (!window.EDITOR_X_HF_INITIALIZED) {
      window.EDITOR_X_HF_INITIALIZED = true;
      this._initHeaderFooterListeners();
    }
  }

  private _insertHeaderHtml() {
    document.body.insertAdjacentHTML(
      'afterbegin',
      Header({ t: this.t, structure: this.headerStructure, config: this.config }),
    );
  }

  private _insertFooterHtml() {
    document.body.insertAdjacentHTML(
      'beforeend',
      Footer({ t: this.t, structure: this.footerStructure, config: this.config }),
    );
  }

  private _initHeaderFooterListeners() {
    if (this.config.isHeaderUsed()) {
      this._initHeaderListeners();
    }
    if (this.config.isFooterUsed()) {
      this._initFooterListeners();
      this._reorderFooterMenu();
    }
    this._disableFocusForMouseEvents();

    window.addEventListener('resize', this._resizeListener);
  }

  private _initHeaderListeners() {
    this._renderLanguagePicker();
    if (this.config.isWixSite()) {
      this._moveHeaderToTop();
    }
    this._enableHeaderToggles();
    this._enableHeaderBiEvents();
    this._loadUserDetails();

    if (this.config.isSticky()) {
      this._makeHeaderSticky();
    }
  }

  private _initFooterListeners() {
    this._enableFooterToggles();
    this._enableFooterBiEvents();
  }

  private _disableFocusForMouseEvents() {
    const headerElement = getHeader();
    const footerElement = getFooter();
    window.addEventListener('keydown', this._handleFirstTab);
    window.addEventListener('mousedown', () => {
      setTimeout(() => {
        if (
          (headerElement && headerElement.contains(document.activeElement)) ||
          (footerElement && footerElement.contains(document.activeElement))
        ) {
          // @ts-expect-error
          document.activeElement.blur();
        }
      }, 0);
    });
  }

  private async _loadUserDetails() {
    const userData = await this.dataFetcher.fetchUserProfileData();
    if (userData) {
      await this._renderUserMenu(userData);
    } else {
      this._renderSignIn();
    }
  }

  private _moveHeaderToTop() {
    const header = getHeader();
    if (header) {
      document.body.insertBefore(header, document.body.firstChild);
    }
  }

  private _enableHeaderToggles() {
    const menuToggle = $(`header [data-hook=${DataHook.MenuToggle}]`) as HTMLElement;
    if (menuToggle) {
      let trapFocusHandler: Function | null = null;
      menuToggle.addEventListener('click', () => {
        const expanded = menuToggle.dataset.expanded === 'true' ? 'false' : 'true';
        menuToggle.dataset.expanded = expanded;
        menuToggle.setAttribute('aria-expanded', expanded);
        logMenuToggle(expanded === 'true');
        if (expanded === 'true') {
          menuToggle.title = this.t('menu_close_navigation');
          menuToggle.setAttribute('aria-label', this.t('menu_close_navigation'));
          document.body.classList.add(headerNavCSS.fixedBody);
          if (!trapFocusHandler) {
            trapFocusHandler = this._trapFocusOnOpenedMenu();
          }
        } else {
          menuToggle.title = this.t('menu_open_navigation');
          menuToggle.setAttribute('aria-label', this.t('menu_open_navigation'));
          document.body.classList.remove(headerNavCSS.fixedBody);
          if (trapFocusHandler) {
            this._removeTrapFocus(trapFocusHandler);
            trapFocusHandler = null;
          }
        }
      });
    }

    const menuItemToggles = $$('header [data-toggle=collapse]');
    if (menuItemToggles.length) {
      Array.from(menuItemToggles).forEach(toggle => {
        toggle.addEventListener('click', () => {
          const container = toggle.parentElement as HTMLLIElement;
          const expanded = container.dataset.expanded === 'true' ? 'false' : 'true';
          toggle.setAttribute('aria-expanded', expanded);
          container.setAttribute('data-expanded', expanded);
        });
      });
    }
  }

  private _renderSignIn = () => {
    const navigation = getNavigationMenu();
    const startNowButton = StartNow({
      link: this.getLoginLink(),
      t: this.t,
    });
    if (this.state.isDesktop) {
      navigation.insertAdjacentHTML('beforeend', startNowButton);
    } else {
      navigation.insertAdjacentHTML('afterbegin', startNowButton);
    }

    const startNowButtonElement = $(`.${headerNavCSS.startNow}`) as HTMLAnchorElement;
    if (startNowButtonElement) {
      wrapLinkWithBiEvent(headerAndFooterBiLogger, startNowButtonElement, hfBiConfig.signinClick, () => ({
        origin: window.location.href,
      }));
    }
  };

  private getLoginLink = () => {
    const params = {
      loginCompName: 'Signup_H',
      referralInfo: 'Editorx_Signup_H',
      sendEmail: 'true',
      view: 'sign-up',
      overrideLocale: this.config.getContentLanguage(),
      postLogin: this.config.getPostLoginLink() || 'https://manage.editorx.com/account/sites&forceRender=true',
      postSignUp: this.config.getPostSignUp(),
      originUrl: window?.location?.href,
    };

    const queryString = Object.entries(params)
      .filter(([_, value]) => value)
      .map(([key, value]) => `${key}=${encodeURIComponent(value as string)}`)
      .join('&');

    return `https://users.editorx.com/signin?${queryString}`;
  };

  private _renderUserMenu = async ({ userName }: UserProfileData) => {
    const container = document.querySelector(`.${headerNavCSS.menu}`);
    const userImage = await this.dataFetcher.fetchUserProfileImage();
    if (container) {
      const userMenu = UserMenu({
        navigation: this.headerStructure.userMenu,
        t: this.t,
        userImage,
        userName,
      });
      if (this.state.isDesktop) {
        container.insertAdjacentHTML('beforeend', userMenu);
      } else {
        container.insertAdjacentHTML('afterbegin', userMenu);
      }
      this._enableUserMenuToggle();
      this._updatePostSignOutLink();
    }
  };

  private _enableUserMenuToggle() {
    const toggle = $(`.${headerNavCSS.userMenu} > .${headerNavCSS.link}`);
    if (toggle) {
      toggle.addEventListener('click', () => {
        const container = toggle.parentElement as HTMLLIElement;
        const expanded = container.dataset.expanded === 'true' ? 'false' : 'true';
        toggle.setAttribute('aria-expanded', expanded);
        container.setAttribute('data-expanded', expanded);
      });
    }
  }

  private _enableFooterToggles() {
    const toggles = document.querySelectorAll<HTMLElement>('footer [data-toggle=collapse]');
    if (toggles) {
      Array.from(toggles).forEach(toggle => {
        toggle.addEventListener('click', () => {
          const expanded = toggle.dataset.expanded === 'true' ? 'false' : 'true';
          toggle.dataset.expanded = expanded;
          toggle.setAttribute('aria-expanded', expanded);
        });
      });
    }
  }

  private _getFooterBiParams(link: HTMLAnchorElement) {
    const button_name = link.dataset.biName || '';
    const menuItem = link.closest(`.${footerMenuCSS.menuItem}`);
    let type = '';
    if (menuItem) {
      const heading = menuItem.querySelector<HTMLElement>(`.${footerMenuCSS.menuHeading} `);
      if (heading) {
        type = heading.dataset.biName || '';
      }
    }
    return {
      button_name,
      type,
      origin: window.location.href,
      url: link.href,
      version: window.EDITOR_X_HF_VERSION,
    };
  }

  private _enableFooterBiEvents() {
    $$(`.${footerMenuCSS.link}`).forEach((element: any) => {
      wrapLinkWithBiEvent(headerAndFooterBiLogger, element, hfBiConfig.clickOnFooter, this._getFooterBiParams);

      element.addEventListener(
        'mouseenter',
        debounce(() => {
          void headerAndFooterBiLogger.log({
            ...this._getFooterBiParams(element),
            evid: hfBiConfig.hoverOnFooter,
          });
        }, 1000),
      );
    });
  }

  private _getHeaderBiParams(link: HTMLAnchorElement) {
    const row = link.dataset.biRow || '';
    const params = {
      origin: window.location.href,
      url: link.href || '',
      row,
      version: window.EDITOR_X_HF_VERSION,
    };
    if (row === 'first') {
      return {
        ...params,
        tab: link.dataset.biName || '',
      };
    }
    const sub_tab = link.dataset.biName || '';
    const menuItem = link.closest(`.${headerNavCSS.menuItem}`);
    let tab = '';
    if (menuItem) {
      const heading = menuItem.querySelector<HTMLAnchorElement>(`.${headerNavCSS.biLink}`);
      if (heading) {
        tab = heading.dataset.biName || '';
      }
    }
    return {
      ...params,
      sub_tab,
      tab,
    };
  }

  private _enableHeaderBiEvents() {
    const headerLogoElement = $(`header .${logoCSS.link}`) as HTMLAnchorElement;
    if (headerLogoElement) {
      wrapLinkWithBiEvent(headerAndFooterBiLogger, headerLogoElement, hfBiConfig.clickOnHeader, () => ({
        origin: window.location.href,
        url: headerLogoElement.href,
        row: 'first',
        tab: 'homepage',
        version: window.EDITOR_X_HF_VERSION,
      }));

      headerLogoElement.addEventListener(
        'mouseenter',
        debounce(() => {
          void headerAndFooterBiLogger.log({
            origin: window.location.href,
            url: headerLogoElement.href,
            row: 'first',
            tab: 'homepage',
            version: window.EDITOR_X_HF_VERSION,
            evid: hfBiConfig.hoverOnHeader,
          });
        }, 1000),
      );
    }

    $$(`.${headerNavCSS.biLink}`).forEach((element: any) => {
      wrapLinkWithBiEvent(headerAndFooterBiLogger, element, hfBiConfig.clickOnHeader, this._getHeaderBiParams);

      element.addEventListener(
        'mouseenter',
        debounce(() => {
          void headerAndFooterBiLogger.log({
            ...this._getHeaderBiParams(element),
            evid: hfBiConfig.hoverOnHeader,
          });
        }, 1000),
      );
    });
  }

  private _handleFirstTab(e: KeyboardEvent) {
    if (e.keyCode === 9) {
      document.body.classList.add('focused');

      window.removeEventListener('keydown', this._handleFirstTab);
      window.addEventListener('mousedown', this._handleMouseDownOnce);
    }
  }

  private _handleMouseDownOnce() {
    document.body.classList.remove('focused');

    window.removeEventListener('mousedown', this._handleMouseDownOnce);
    window.addEventListener('keydown', this._handleFirstTab);
  }

  private _makeHeaderSticky() {
    const headerElement = $('#EDITOR_X_HEADER > div');
    if (headerElement) {
      headerElement.classList.add(headerCSS.fixed);
    }
  }

  private _reorderFooterMenu() {
    if (this.state.isDesktop) {
      setDesktopOrder();
    } else {
      setMobileOrder();
    }

    function setMobileOrder() {
      const footerMenu = $(`[data-hook=${DataHook.FooterMenu}]`);
      const footerDescription = $(`[data-hook=${DataHook.FooterDescription}]`);
      if (footerMenu && footerDescription) {
        const container = footerMenu.parentElement;
        if (container && container.children[0] === footerMenu) {
          container.removeChild(footerMenu);
          container.appendChild(footerMenu);
        }
      }
    }

    function setDesktopOrder() {
      const footerMenu = $(`[data-hook=${DataHook.FooterMenu}]`);
      const footerDescription = $(`[data-hook=${DataHook.FooterDescription}]`);
      if (footerMenu && footerDescription) {
        const container = footerMenu.parentElement;
        if (container && container.children[0] !== footerMenu) {
          container.removeChild(footerDescription);
          container.appendChild(footerDescription);
        }
      }
    }
  }

  private _trapFocus(
    firstElement: HTMLElement | (() => HTMLElement | null),
    lastElement: HTMLElement | (() => HTMLElement | null),
  ) {
    const handler = (e: KeyboardEvent) => {
      const isTabPressed = e.key === 'Tab' || e.keyCode === 9;
      const firstFocusableElement = typeof firstElement === 'function' ? firstElement() : firstElement;
      const lastFocusableElement = typeof lastElement === 'function' ? lastElement() : lastElement;

      if (!isTabPressed || !firstFocusableElement || !lastFocusableElement) {
        return;
      }

      if (e.shiftKey) {
        // if shift key pressed for shift + tab combination
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus();
          e.preventDefault();
        }
      } else {
        // if tab key is pressed
        if (document.activeElement === lastFocusableElement) {
          firstFocusableElement.focus();
          e.preventDefault();
        }
      }
    };
    document.addEventListener('keydown', handler);
    return handler;
  }

  private _trapFocusOnOpenedMenu() {
    const firstFocusableElement = $(`[data-hook=${DataHook.Logo}]`) as HTMLElement;

    const getLastFocusableElement = () => {
      if (!this.state.isDesktop) {
        const lastLanguageItem =
          this.headerStructure.languagePickerMenu[this.headerStructure.languagePickerMenu.length - 1];
        const lastFocusableElement = getLanguagePickerButton();
        const parentLiElement = lastFocusableElement.parentElement;
        if (parentLiElement?.getAttribute('data-expanded') === 'true') {
          return $(`[data-bi-name="${lastLanguageItem.name}"]`) as HTMLElement | null;
        }
        return lastFocusableElement;
      }
      return null;
    };

    return firstFocusableElement ? this._trapFocus(firstFocusableElement, getLastFocusableElement) : null;
  }

  private _removeTrapFocus(handler: Function) {
    document.removeEventListener('keydown', handler as (e: KeyboardEvent) => any);
  }

  private _resizeListener = (): void => {
    const newState: HeaderFooterState = this._updateState();
    if (this.state.isDesktop !== newState.isDesktop) {
      this.state = newState;
      if (this.config.isHeaderUsed()) {
        this._reorderHeaderMenu();
      }

      if (this.config.isFooterUsed()) {
        this._reorderFooterMenu();
      }
    }
  };

  private _reorderHeaderMenu() {
    const userMenu = $(`[data-hook="${DataHook.UserMenu}"]`);
    if (userMenu) {
      const headerMenu = userMenu.parentElement;
      if (headerMenu) {
        headerMenu.removeChild(userMenu);
        if (this.state.isDesktop) {
          headerMenu.appendChild(userMenu);
        } else {
          headerMenu.insertBefore(userMenu, headerMenu.firstChild);
        }
      }
    }
  }

  private _renderLanguagePicker() {
    const navigation = getNavigationMenu();
    navigation.insertAdjacentHTML(
      'beforeend',
      LanguagePicker({
        items: this.headerStructure.languagePickerMenu,
        t: this.t,
      }),
    );
  }

  private _getRedirectToLinks = () => {
    const location = window.location;
    return supportedLanguages.reduce<Record<SupportedLanguage, string>>((acc, lang) => {
      const alternateLink = this._extractAlternateLink(lang);
      const alternateHref = alternateLink && alternateLink.getAttribute('href');
      const protocol = location && location.protocol ? location.protocol : 'https:';
      const domain = `${lang === 'en' ? 'www' : lang}.editorx.com`;
      const path = location ? `${location.pathname || ''}${location.search || ''}` : '';
      acc[lang] = alternateHref || `${protocol}//${domain}${path}`;
      return acc;
    }, {} as Record<SupportedLanguage, string>);
  };

  private _extractAlternateLink(language: string) {
    if (typeof document === 'undefined') {
      return null;
    }
    return (
      document.querySelector(`link[rel="alternate"][hreflang="${language}"]`) ||
      document.querySelector(`link[rel="alternate"][hreflang="x-default"]`)
    );
  }

  private _updatePostSignOutLink() {
    const redirectTo = this.config.getPostSignOut();
    if (redirectTo) {
      const signOutEl = $<HTMLAnchorElement>('[data-bi-name=signout]')!;
      const url = new URL(signOutEl.href);
      const params = new URLSearchParams(url.search);
      params.append('redirectTo', redirectTo);
      const urlWithoutParams = url.href.split('?')[0];
      signOutEl.href = `${urlWithoutParams}?${params.toString()}`;
    }
  }
}
