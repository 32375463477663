interface TagAttributes {
  [name: string]: string | undefined;
}

export const createTag = (tagName: string, value: string | number, attributes: TagAttributes) => {
  const parameters = Object.keys(attributes)
    .filter(key => {
      return attributes[key] !== undefined && attributes[key] !== null;
    })
    .map(key => {
      return `${key}="${attributes[key]}"`;
    });

  return `<${tagName} ${parameters.join(' ')}>${value}</${tagName}>`;
};

export const mapTags = <T>(items: T[], iterator: (item: T) => string): string => {
  return items.map(iterator).join('');
};
