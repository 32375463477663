import { Messages } from './translate';
import { Config } from './config';

export interface UserProfileData {
  userName: string;
}

enum ENV {
  Local = 'local',
  Production = 'prod',
}

const API = {
  userProfile: {
    [ENV.Production]: 'https://manage.editorx.com/_api/wix-html-login-webapp/user/getUserDetails',
    [ENV.Local]: '/getUserDetails',
  },
  profileImage: {
    [ENV.Production]: 'https://manage.editorx.com/_api/account-info-server/profileimage',
    [ENV.Local]: '/profileimage',
  },
  staticBase: {
    [ENV.Production]: '//static.parastorage.com/services/editorx-header-and-footer/',
    [ENV.Local]: 'http://localhost:3200',
  },
};

export class DataFetcher {
  private readonly config: Config;
  private readonly editorxHnFVersion: string | undefined;

  constructor(config: Config) {
    this.config = config;
    this.editorxHnFVersion = window.EDITOR_X_HF_VERSION;
  }

  fetchTranslations() {
    return fetch(`${this.getStaticBaseUrl()}/assets/locales/messages_${this.config.getContentLanguage()}.json`).then(
      response => response.json(),
    ) as Promise<Messages>;
  }

  private getStaticBaseUrl() {
    if (this.getEnv() === ENV.Production && this.editorxHnFVersion) {
      return `${API.staticBase[ENV.Production]}/${this.editorxHnFVersion}`;
    } else {
      return API.staticBase[ENV.Local];
    }
  }

  private getEnv(): ENV {
    return window.EDITOR_X_HF_DEBUG ? ENV.Local : ENV.Production;
  }

  async fetchUserProfileData() {
    try {
      const userProfileResponse = (await fetch(API.userProfile[this.getEnv()], { credentials: 'include' }).then(res =>
        res.json(),
      )) as {
        payload: UserProfileData | null;
      };
      return userProfileResponse.payload;
    } catch (e) {
      return null;
    }
  }

  async fetchUserProfileImage() {
    const defaultProfileImage = `${this.getStaticBaseUrl()}/assets/default-profile-image.svg`;
    try {
      const { profileImage } = (await fetch(API.profileImage[this.getEnv()], { credentials: 'include' }).then(res =>
        res.json(),
      )) as {
        profileImage: string;
      };
      return profileImage === '' ? defaultProfileImage : profileImage;
    } catch (e) {
      return defaultProfileImage;
    }
  }
}
