import { DropdownArrow } from '../../DropdownArrow/DropdownArrow';
import { NavigationItem, SEPARATOR, Separator } from '../../../data/types';
import { createTag } from '../../../modules/tag-creators';
import s from './Navigation.scss';
import { DataHook } from '../../../modules/dataHooks';

export interface UserMenuProps {
  userName: string;
  userImage?: string;
  navigation: (NavigationItem | Separator)[];
  t(key: string): string;
}

const UserImage = (userImage?: string, alt?: string) => {
  return `
    <img class="${s.userImage}" src="${userImage}" alt="${alt}" />
  `;
};

const MenuItem = (item: NavigationItem | Separator, t: (key: string) => string) => {
  if (item === SEPARATOR) {
    return `
      <li class="${s.subMenuItem} ${s.separator}"></li>
    `;
  }
  return `
    <li class="${s.subMenuItem}">
      ${createTag('a', t(item.text), {
        class: s.link,
        target: item.target,
        href: item.href,
        'data-bi-name': item.name,
      })}
    </li>
  `;
};

export const UserMenu = ({ navigation, t, userImage, userName }: UserMenuProps) => {
  const id = `user-menu-user_profile`;
  return `
    <li
      data-hook="${DataHook.UserMenu}"
      class="${s.menuItem} ${s.userMenu}"
      data-expanded="false"
    >
      ${UserImage(userImage, t('editorx_profile_image'))}
      <button
        id="${id}"
        class="${s.link}"
        title="${userName}"
        aria-label="User Profile"
        data-toggle="collapse"
        aria-expanded="false"
      >
        ${userName}
        ${DropdownArrow(s.arrow)}
      </button>
      <div
        class="${s.subMenuContainer} items-${navigation.length}"
        role="region"
        aria-labelledby="${id}"
      >
        <ul class="${s.subMenu}">
          ${navigation.map(item => MenuItem(item, t)).join('')}
        </ul>
      </div>
    </li>
  `;
};
