import { MultiLangFooterStructure } from './types';

export const footerStructure: MultiLangFooterStructure = {
  socialLinks: {
    facebook: {
      text: 'social_link_facebook',
      href: 'https://www.facebook.com/editorx',
      target: '_blank',
      name: 'facebook',
      subs: [],
    },
    instagram: {
      text: 'social_link_instagram',
      href: 'https://www.instagram.com/editorx/',
      target: '_blank',
      name: 'instagram',
      subs: [],
    },
    twitter: {
      text: 'social_link_twitter',
      href: 'https://twitter.com/editorx',
      target: '_blank',
      name: 'twitter',
      subs: [],
    },
    youtube: {
      text: 'social_link_youtube',
      href: 'https://www.youtube.com/channel/UCdJdISP2jXUxBglXAHdcpSw',
      target: '_blank',
      name: 'youtube',
      subs: [],
    },
  },
  nav: [
    {
      text: 'footer_menu_product',
      name: 'product',
      subs: [
        {
          text: 'footer_sub_menu_design',
          href: {
            en: 'https://www.editorx.com/features/design',
            ru: 'https://ru.editorx.com/features/design',
            fr: 'https://fr.editorx.com/features/design',
            es: 'https://es.editorx.com/features/design',
            pt: 'https://pt.editorx.com/features/design',
            de: 'https://de.editorx.com/features/design',
            ja: 'https://ja.editorx.com/features/design',
          },
          name: 'design',
          subs: [],
        },
        {
          text: 'footer_sub_menu_development',
          href: {
            en: 'https://www.editorx.com/features/development',
            ru: 'https://ru.editorx.com/features/development',
            fr: 'https://fr.editorx.com/features/development',
            es: 'https://es.editorx.com/features/development',
            pt: 'https://pt.editorx.com/features/development',
            de: 'https://de.editorx.com/features/development',
            ja: 'https://ja.editorx.com/features/development',
          },
          name: 'development',
          subs: [],
        },
        {
          text: 'footer_sub_menu_business',
          href: {
            en: 'https://www.editorx.com/features/business-ecommerce',
            ru: 'https://ru.editorx.com/features/business-ecommerce',
            fr: 'https://fr.editorx.com/features/business-ecommerce',
            es: 'https://es.editorx.com/features/business-ecommerce',
            pt: 'https://pt.editorx.com/features/business-ecommerce',
            de: 'https://de.editorx.com/features/business-ecommerce',
            ja: 'https://ja.editorx.com/features/business-ecommerce',
          },
          name: 'business_ecommerce',
          subs: [],
        },
        {
          text: 'footer_sub_menu_cms',
          href: 'https://www.editorx.com/features/cms',
          name: 'cms',
        },
        {
          text: 'footer_sub_menu_marketing_tools',
          href: {
            en: 'https://www.editorx.com/features/marketing-seo',
            ru: 'https://ru.editorx.com/features/marketing-seo',
            fr: 'https://fr.editorx.com/features/marketing-seo',
            es: 'https://es.editorx.com/features/marketing-seo',
            pt: 'https://pt.editorx.com/features/marketing-seo',
            de: 'https://de.editorx.com/features/marketing-seo',
            ja: 'https://ja.editorx.com/features/marketing-seo',
          },
          name: 'marketing_seo',
          subs: [],
        },
        {
          text: 'footer_sub_menu_collaboration',
          href: {
            en: 'https://www.editorx.com/collaboration',
            ru: 'https://ru.editorx.com/collaboration',
            fr: 'https://fr.editorx.com/collaboration',
            es: 'https://es.editorx.com/collaboration',
            pt: 'https://pt.editorx.com/collaboration',
            de: 'https://de.editorx.com/collaboration',
            ja: 'https://ja.editorx.com/collaboration',
          },
          name: 'collaboration',
          subs: [],
        },
        {
          text: 'footer_sub_menu_hosting_domains',
          href: {
            en: 'https://www.editorx.com/features/web-solutions',
            ru: 'https://ru.editorx.com/features/web-solutions',
            fr: 'https://fr.editorx.com/features/web-solutions',
            es: 'https://es.editorx.com/features/web-solutions',
            pt: 'https://pt.editorx.com/features/web-solutions',
            de: 'https://de.editorx.com/features/web-solutions',
            ja: 'https://ja.editorx.com/features/web-solutions',
          },
          name: 'web_solutions',
          subs: [],
        },
        {
          text: 'footer_sub_menu_premium_plans',
          href: {
            en: 'https://www.editorx.com/upgrade/website',
            ru: 'https://ru.editorx.com/upgrade/website',
            fr: 'https://fr.editorx.com/upgrade/website',
            es: 'https://es.editorx.com/upgrade/website',
            pt: 'https://pt.editorx.com/upgrade/website',
            de: 'https://de.editorx.com/upgrade/website',
            ja: 'https://ja.editorx.com/upgrade/website',
          },
          name: 'pricing_plans',
          subs: [],
        },
        {
          text: 'footer_sub_menu_partner_program',
          href: {
            en: 'https://www.wix.com/partners',
            ru: 'https://ru.wix.com/partners',
            fr: 'https://fr.wix.com/partners',
            es: 'https://es.wix.com/partners',
            pt: 'https://pt.wix.com/partners',
            de: 'https://de.wix.com/partners',
            ja: 'https://ja.wix.com/partners',
          },
          name: 'partners',
          target: '_blank',
          subs: [],
        },
        {
          text: 'footer_sub_menu_enterprise',
          href: 'https://www.editorx.com/enterprise',
          target: '_blank',
          name: 'enterprise',
          subs: [],
        },
      ],
    },
    {
      text: 'footer_menu_resources',
      name: 'resources',
      subs: [
        {
          text: 'footer_sub_menu_site_expressions',
          href: {
            en: 'https://www.editorx.com/website-templates',
            ru: 'https://ru.editorx.com/website-templates',
            fr: 'https://fr.editorx.com/website-templates',
            es: 'https://es.editorx.com/website-templates',
            pt: 'https://pt.editorx.com/website-templates',
            de: 'https://de.editorx.com/website-templates',
            ja: 'https://ja.editorx.com/website-templates',
          },
          name: 'design_expressions',
          subs: [],
        },
        {
          text: 'sub_menu_created_on_editorx',
          href: 'https://www.editorx.com/inspiration',
          name: 'created_on_editorx',
          target: '_blank',
          subs: [],
        },
        {
          text: 'footer_sub_menu_academy_x',
          href: 'https://www.editorx.com/academy',
          name: 'academy',
          target: '_blank',
          subs: [],
        },
        {
          text: 'footer_sub_menu_forum',
          href: 'https://www.editorxcommunity.com',
          name: 'forum',
          target: '_blank',
          subs: [],
        },
        {
          text: 'footer_sub_menu_release_updates',
          href: 'https://www.editorx.com/features/releases-and-updates',
          name: 'releases_updates',
          target: '_blank',
          subs: [],
        },
        {
          text: 'footer_sub_menu_coming_soon',
          href: 'https://www.editorx.com/features/coming-soon',
          name: 'coming_soon',
          target: '_blank',
          subs: [],
        },
        {
          text: 'footer_sub_menu_help_center',
          href: {
            en: 'https://support.wix.com/en/editor-x-beta/about-editor-x-7952617',
            ru: 'https://support.wix.com/ru/editor-x-1140141/%D0%BF%D1%80%D0%BE-editor-x',
            fr: 'https://support.wix.com/fr/%C3%A9diteur-x-beta/%C3%A0-propos-de-l%C3%A9diteur-x',
            es: 'https://support.wix.com/es/editor-x-7596793/acerca-del-editor-x',
            pt: 'https://support.wix.com/pt/sobre-o-editor-x/sobre-o-editor-x',
            de: 'https://support.wix.com/de/%C3%BCber-editor-x/%C3%BCber-editor-x',
            ja: 'https://support.wix.com/ja/editor-x-2063793/editor-x-%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6',
          },
          name: 'help_center',
          target: '_blank',
          subs: [],
        },
      ],
    },
    {
      text: 'footer_menu_company',
      name: 'company',
      subs: [
        {
          text: 'footer_sub_menu_about',
          href: {
            en: 'https://www.editorx.com/info/about-us',
            ru: 'https://ru.editorx.com/info/about-us',
            fr: 'https://fr.editorx.com/info/about-us',
            es: 'https://es.editorx.com/info/about-us',
            pt: 'https://pt.editorx.com/info/about-us',
            de: 'https://de.editorx.com/info/about-us',
            ja: 'https://ja.editorx.com/info/about-us',
          },
          name: 'about_us',
          subs: [],
        },
        {
          text: 'footer_sub_menu_privacy_policy',
          href: {
            en: 'https://www.editorx.com/info/privacy',
            ru: 'https://ru.editorx.com/info/privacy',
            fr: 'https://fr.editorx.com/info/privacy',
            es: 'https://es.editorx.com/info/privacy',
            pt: 'https://pt.editorx.com/info/privacy',
            de: 'https://de.editorx.com/info/privacy',
            ja: 'https://ja.editorx.com/info/privacy',
          },
          name: 'privacy_policy',
          subs: [],
        },
        {
          text: 'footer_sub_menu_terms_of_use',
          href: {
            en: 'https://www.editorx.com/info/terms-of-use',
            ru: 'https://ru.editorx.com/info/terms-of-use',
            fr: 'https://fr.editorx.com/info/terms-of-use',
            es: 'https://es.editorx.com/info/terms-of-use',
            pt: 'https://pt.editorx.com/info/terms-of-use',
            de: 'https://de.editorx.com/info/terms-of-use',
            ja: 'https://ja.editorx.com/info/terms-of-use',
          },
          name: 'terms_of_use',
          subs: [],
        },
        {
          text: 'footer_sub_menu_contact_us',
          href: {
            en: 'https://www.editorx.com/contact-us',
            ru: 'https://ru.wix.com/about/contact-us',
            fr: 'https://fr.wix.com/about/contact-us',
            es: 'https://es.wix.com/about/contact-us',
            pt: 'https://pt.wix.com/about/contact-us',
            de: 'https://de.wix.com/about/contact-us',
            ja: 'https://ja.wix.com/about/contact-us',
          },
          target: '_blank',
          name: 'contact_us',
          subs: [],
        },
        {
          text: 'footer_sub_menu_site_map',
          href: {
            en: 'https://www.editorx.com/info/sitemap',
            ru: 'https://ru.editorx.com/info/sitemap',
            fr: 'https://fr.editorx.com/info/sitemap',
            es: 'https://es.editorx.com/info/sitemap',
            pt: 'https://pt.editorx.com/info/sitemap',
            de: 'https://de.editorx.com/info/sitemap',
            ja: 'https://ja.editorx.com/info/sitemap',
          },
          name: 'sitemap',
          subs: [],
        },
        {
          text: 'footer_sub_menu_impressum',
          href: 'https://de.wix.com/impressum',
          name: 'impressum',
          target: '_blank',
          lang: ['de'],
        },
      ],
    },
  ],
};
