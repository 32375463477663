import { NavigationItem } from '../../../data/types';
import { MenuItem } from './MenuItem';
import { MenuToggle } from './MenuToggle/MenuToggle';
import { MoreMenu } from './MoreMenu/MoreMenu';
import s from './Navigation.scss';
import { DataHook } from '../../../modules/dataHooks';

const VISIBLE_ITEMS_COUNT = 3;

export interface NavigationProps {
  navigation: NavigationItem[];
  t(key: string): string;
}

export const Navigation = ({ navigation, t }: NavigationProps) => {
  return `
    ${MenuToggle({ t })}
    <nav class="${s.overlay}" aria-label="${t('nav_aria_label')}">
      <ul class="${s.menu}" data-hook="${DataHook.NavigationMenu}">
        ${navigation
          .slice(0, VISIBLE_ITEMS_COUNT)
          .map(item => MenuItem({ item, t }))
          .join('')}
      </ul>
    </nav>
  `;
};
