import { Logo } from '../../Logo/Logo';
import s from './Description.scss';
import { Translator } from '../../../modules/translate';
import { Config } from '../../../modules/config';
import { DataHook } from '../../../modules/dataHooks';

interface DescriptionProps {
  t: Translator;
  config: Config;
}

export const Description = ({ t, config }: DescriptionProps) => {
  return `
    <div class="${s.wrapper}" data-hook="${DataHook.FooterDescription}">
      ${Logo({ t, language: config.getContentLanguage() })}
      <div class="${s.description}">${t('footer_description')}</div>
    </div>
  `;
};
