import { isSupportedLanguage, SupportedLanguage } from '../data/languages';

export type HeaderFooterParams = {
  sticky?: boolean;
  language?: string;
  header?: boolean;
  footer?: boolean;
  isWixSite?: boolean;
  postLogin?: string;
  postSignUp?: string;
  postSignOut?: string;
  mainContentSelector?: string;
};

export class Config {
  constructor(private readonly data: HeaderFooterParams) {}

  public isSticky = () => this.data.sticky ?? true;

  public isHeaderUsed = () => this.data.header ?? true;

  public isFooterUsed = () => this.data.footer ?? true;

  public getContentLanguage = (): SupportedLanguage =>
    isSupportedLanguage(this.data.language) ? this.data.language : 'en';

  public getPageLanguage = () => this.data.language;

  public isWixSite = () => this.data.isWixSite ?? false;

  public getPostLoginLink = () => this.data.postLogin;

  public getPostSignUp = () => this.data.postSignUp;

  public getPostSignOut = () => this.data.postSignOut;

  public getMainContentSelector = () => this.data.mainContentSelector;
}
