import { Translator } from '../../modules/translate';
import { Copyright } from './Copyright/Copyright';
import { Description } from './Description/Description';
import s from './Footer.scss';
import { Menu } from './Menu/Menu';
import { SocialIcons } from './SocialIcons/SocialIcons';
import { FooterStructure } from '../../data/types';
import { Config } from '../../modules/config';

interface FooterProps {
  t: Translator;
  structure: FooterStructure;
  config: Config;
}

const Footer = ({ t, structure: { socialLinks, nav }, config }: FooterProps) => {
  return `
    <footer class="${s.footer}" id="EDITOR_X_FOOTER" style="visibility: hidden">
      <div class="${s.top}">
        ${Menu({ items: nav, t })}
        ${Description({ t, config })}
      </div>
      <div class="${s.bottom}">
        ${Copyright({ t })}
        ${SocialIcons({ socialLinks, t })}
      </div>
    </footer>
  `;
};

export default Footer;
