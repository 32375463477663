import Promise from 'promise-polyfill';
import { Config, HeaderFooterParams } from './modules/config';
import { DataFetcher } from './modules/DataFetcher';
import { loadExperiments } from './modules/experiments';
import { HeaderFooterController } from './modules/HeaderFooterController';

export async function init(params: HeaderFooterParams) {
  const config = new Config(params);
  const dataFetcher = new DataFetcher(config);

  const [messages, experiments] = await Promise.all([dataFetcher.fetchTranslations(), loadExperiments()]);

  const headerFooterController = new HeaderFooterController({ config, messages, experiments, dataFetcher });

  headerFooterController.init();
}
