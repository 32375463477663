import { DataHook } from './dataHooks';

export const $ = <T extends Element>(query: string, context = document) => context.querySelector<T>(query);

export const $$ = (query: string, context = document) => Array.from(context.querySelectorAll(query));

export const getHeader = () => $<HTMLElement>('#EDITOR_X_HEADER');

export const getFooter = () => $<HTMLElement>('#EDITOR_X_FOOTER');

export const getNavigationMenu = () => $<HTMLUListElement>(`[data-hook="${DataHook.NavigationMenu}"]`)!;

export const getLanguagePickerButton = () => $<HTMLButtonElement>('[data-bi-name="language"]')!;
