import messages from '../assets/locales/messages_en.json';
import { SupportedLanguage } from './languages';

export interface MultiLangFooterStructure {
  socialLinks: SocialLinks;
  nav: MultiLangNavigationItem[];
}

export interface FooterStructure {
  socialLinks: SocialLinks;
  nav: NavigationItem[];
}

export interface SocialLinks {
  facebook: NavigationItem;
  instagram: NavigationItem;
  twitter: NavigationItem;
  youtube: NavigationItem;
}

export const SEPARATOR = 'SEPARATOR';

export type Separator = typeof SEPARATOR;

export type TextKey = keyof typeof messages;

export type UserMenuStructure = (NavigationItem | Separator)[];

export type MultiLangHeaderStructure = {
  languagePickerMenu: LanguageItem[];
  userMenu: UserMenuStructure;
  nav: MultiLangNavigationItem[];
};
export type HeaderStructure = Omit<MultiLangHeaderStructure, 'nav'> & {
  nav: NavigationItem[];
};

export type MultiLangNavigationItem = {
  text: TextKey;
  href?: LanguageHrefMap;
  name?: string;
  lang?: SupportedLanguage[];
  target?: '_blank';
  subs?: MultiLangNavigationItem[];
};

export type NavigationItem = Omit<MultiLangNavigationItem, 'href' | 'subs' | 'lang' | 'text'> & {
  href?: string;
  subs?: NavigationItem[];
  text: string;
};

export type LanguageItem = {
  text: string;
  name: SupportedLanguage;
  href?: string;
};

export type LanguageHrefMap = Partial<Record<SupportedLanguage, string>> | string;
