import s from './Logo.scss';
import { Translator } from '../../modules/translate';

interface LogoProps {
  dataHook?: string;
  t: Translator;
  language: string;
}

export const Logo = ({ dataHook, t, language }: LogoProps) => {
  const href = `https://${language === 'en' ? 'www' : language}.editorx.com`;
  return `
    <div class="${s.logo}">
      <a
        class="${s.link}"
        href="${href}"
        data-hook="${dataHook || ''}"
        title="${t('logo_title')}"
      ></a>
    </div>
  `;
};
