import { LanguageItem } from '../../../data/types';
import { Translator } from '../../../modules/translate';
import s from '../Navigation/Navigation.scss';
import { SubMenu } from '../Navigation/SubMenu';
import { DropdownArrow } from '../../DropdownArrow/DropdownArrow';
import { LanguagesIcon } from './LanguagesIcon';

interface LanguagePickerProps {
  items: LanguageItem[];
  t: Translator;
}

export const LanguagePicker = ({ items, t }: LanguagePickerProps) => {
  const id = `menu-item-languages`;
  return `
    <li
      class="${s.menuItem}"
      data-expanded="false"
    >
      <button
      id="${id}"
      class="${s.link} ${s.biLink} ${s.languageButton}"
      data-bi-name="language"
      data-bi-row="first"
      data-toggle="collapse"
      aria-expanded="false"
    >
      ${LanguagesIcon()}
      ${DropdownArrow(s.arrow)}
    </button>
      ${SubMenu({ labelledBy: id, items, t })}
    </li>
  `;
};
