import { MultiLangHeaderStructure, SEPARATOR } from './types';

export const headerStructure: MultiLangHeaderStructure = {
  nav: [
    {
      text: 'editorx_menu_resources',
      name: 'resources',
      subs: [
        {
          text: 'sub_menu_academy_x',
          href: 'https://www.editorx.com/academy',
          name: 'academy_x',
          target: '_blank',
        },
        {
          text: 'sub_menu_created_on_editorx',
          href: 'https://www.editorx.com/inspiration',
          name: 'created_on_editorx',
          target: '_blank',
        },
      ],
    },
  ],
  languagePickerMenu: [
    {
      text: 'English',
      name: 'en',
    },
    {
      text: 'Deutsch',
      name: 'de',
    },
    {
      text: 'Español',
      name: 'es',
    },
    {
      text: 'Français',
      name: 'fr',
    },
    {
      text: '日本語',
      name: 'ja',
    },
    {
      text: 'Português',
      name: 'pt',
    },
    {
      text: 'Русский',
      name: 'ru',
    },
  ],
  userMenu: [
    {
      text: 'editorx_menu_account_settings',
      name: 'account_settings',
      href: 'https://manage.editorx.com/account/account-settings',
    },
    {
      text: 'editorx_menu_billing_and_payments',
      name: 'billing_payments',
      href: 'https://manage.editorx.com/wix/api/PaymentConsole',
    },
    {
      text: 'editorx_menu_vouchers',
      name: 'vouchers',
      href: 'https://manage.editorx.com/account/vouchers',
    },
    SEPARATOR,
    {
      text: 'editorx_menu_my_sites',
      name: 'my_sites',
      href: 'https://manage.editorx.com/account/sites',
    },
    {
      text: 'menu_create_new_site',
      name: 'create_new_site',
      href: 'https://manage.editorx.com/create-site?ref=editorx-header',
    },
    SEPARATOR,
    {
      text: 'menu_logout',
      name: 'signout',
      href: 'https://manage.editorx.com/signout',
    },
  ],
};
