import s from './MenuToggle.scss';
import { DataHook } from '../../../../modules/dataHooks';

export interface MenuToggleProps {
  t(key: string): string;
}

export const MenuToggle = ({ t }: MenuToggleProps) => {
  return `
    <button
      class="${s.hamburger}"
      title="${t('menu_open_navigation')}"
      aria-label="${t('menu_open_navigation')}"
      tabindex="0"
      data-hook="${DataHook.MenuToggle}"
      data-toggle="collapse"
      aria-haspopup="true"
      data-expanded="false"
      aria-expanded="false"
    >
      <span class="${s.hamburgerBox}">
        <span class="${s.hamburgerInner}"></span>
      </span>
    </button>
  `;
};
