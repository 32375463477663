import {
  FooterStructure,
  HeaderStructure,
  LanguageHrefMap,
  NavigationItem,
  MultiLangNavigationItem,
  MultiLangHeaderStructure,
  MultiLangFooterStructure,
} from './types';
import { SupportedLanguage } from './languages';
import { headerStructure } from './headerStructure';
import { footerStructure } from './footerStructure';

export class StructureBuilder {
  private headerStructure: MultiLangHeaderStructure = headerStructure;
  private footerStructure: MultiLangFooterStructure = footerStructure;

  private _convertToNavigationItem(item: MultiLangNavigationItem, language: SupportedLanguage): NavigationItem {
    const subs = item.subs?.reduce<NavigationItem[]>((acc, subItem) => {
      if (this._isNavigationItemSupportCurrentLanguage(subItem, language)) {
        acc.push(this._convertToNavigationItem(subItem, language));
      }
      return acc;
    }, []);
    const href = this._getHrefByLanguage(item.href, language);
    return {
      ...item,
      href,
      subs,
    };
  }

  private _getHrefByLanguage(hrefMap: LanguageHrefMap | undefined, language: SupportedLanguage): string | undefined {
    if (typeof hrefMap === 'string' || !hrefMap) {
      return hrefMap;
    }
    return hrefMap[language] || 'no-link';
  }

  private _isNavigationItemSupportCurrentLanguage(item: MultiLangNavigationItem, language: SupportedLanguage) {
    return !item.lang || item.lang.includes(language);
  }

  public getHeaderStructureByLanguage(
    headerLanguage: SupportedLanguage,
    pageLanguage?: string,
    redirectToLinks?: Record<SupportedLanguage, string>,
  ): HeaderStructure {
    return {
      ...this.headerStructure,
      languagePickerMenu: this.headerStructure.languagePickerMenu.map(item => {
        const languageRedirectToLink = redirectToLinks && redirectToLinks[item.name];
        if (item.name !== pageLanguage && languageRedirectToLink) {
          item.href = this._setLanguageUrl(item.name, languageRedirectToLink);
        }
        return item;
      }),
      nav: this.headerStructure.nav.reduce<NavigationItem[]>((acc, item) => {
        if (this._isNavigationItemSupportCurrentLanguage(item, headerLanguage)) {
          acc.push(this._convertToNavigationItem(item, headerLanguage));
        }
        return acc;
      }, []),
    };
  }
  public getFooterStructureByLanguage(language: SupportedLanguage): FooterStructure {
    return {
      socialLinks: this.footerStructure.socialLinks,
      nav: this.footerStructure.nav.reduce<NavigationItem[]>((acc, item) => {
        if (this._isNavigationItemSupportCurrentLanguage(item, language)) {
          acc.push(this._convertToNavigationItem(item, language));
        }
        return acc;
      }, []),
    };
  }

  private _setLanguageUrl = (language: SupportedLanguage, redirectTo: string) => {
    return `https://users.editorx.com/wix-users/auth/setLanguage?language=${language}&redirectTo=${encodeURIComponent(
      redirectTo,
    )}`;
  };
}
