import { DropdownArrow } from '../../DropdownArrow/DropdownArrow';
import { NavigationItem } from '../../../data/types';
import s from './Menu.scss';
import { createTag, mapTags } from '../../../modules/tag-creators';
import { DataHook } from '../../../modules/dataHooks';

interface MenuProps {
  items: NavigationItem[];
  t(key: string): string;
}

const SubMenuItem = (item: NavigationItem, t: (key: string) => string) => {
  return `
    <li class="${s.subMenuItem}">
      ${createTag('a', t(item.text), {
        class: s.link,
        target: item.target,
        href: item.href,
        tabindex: '0',
        'data-bi-name': item.name,
      })}
    </li>
  `;
};

const SubMenu = ({ items, t }: MenuProps) => {
  return `
    <ul class="${s.subMenu}">
      ${mapTags(items, item => SubMenuItem(item, t))}
    </ul>
  `;
};

const MenuItem = (item: NavigationItem, t: (key: string) => string) => {
  return `
    <li
     class="${s.menuItem}"
     tabindex="0"
     aria-haspopup="true"
     data-expanded="false"
     data-toggle="collapse"
    >
      <span class="${s.menuHeading}" data-bi-name="${item.name}">
        ${t(item.text)}
      </span>
      ${!item.subs?.length ? '' : DropdownArrow(s.arrow)}
      ${!item.subs?.length ? '' : SubMenu({ items: item.subs, t })}
    </li>
  `;
};

export const Menu = ({ items, t }: MenuProps) => {
  return `
    <ul class="${s.menu}" data-hook="${DataHook.FooterMenu}">
      ${mapTags(items, item => MenuItem(item, t))}
    </ul>
  `;
};
