import { NavigationItem } from '../../../data/types';
import { createTag } from '../../../modules/tag-creators';
import { DropdownArrow } from '../../DropdownArrow/DropdownArrow';
import s from './Navigation.scss';
import { SubMenu } from './SubMenu';

export interface MenuItemProps {
  item: NavigationItem;
  t(key: string): string;
}

export const MenuItemButton = ({ item, t }: MenuItemProps, id: string) => {
  if (item.href) {
    return createTag('a', t(item.text), {
      class: `${s.link} ${s.biLink}`,
      target: item.target,
      href: item.href,
      'data-bi-name': item.name,
      'data-bi-row': 'first',
    });
  }

  return `
    <button
      id="${id}"
      class="${s.menuItemButton} ${s.link} ${s.biLink}"
      data-bi-name="${item.name}"
      data-bi-row="first"
      data-toggle="collapse"
      aria-expanded="false"
    >
      ${t(item.text)}
      ${DropdownArrow(s.arrow)}
    </button>
  `;
};

export const MenuItem = ({ item, t }: MenuItemProps) => {
  const id = `menu-item-${item.name}`;
  return `
    <li
      class="${s.menuItem}"
      data-expanded="false"
    >
      ${MenuItemButton({ item, t }, id)}
      ${SubMenu({ labelledBy: id, items: item.subs, t })}
    </li>
  `;
};
