// import WixExperiments, { ExperimentsProps } from '@wix/wix-experiments';

export const DEFAULT_EXPERIMENT_VALUE: Experiments = {};

export type Experiments = {};

export const loadExperiments = async (): Promise<Experiments> => {
  return DEFAULT_EXPERIMENT_VALUE;
  // try {
  //   const wixExperimentsOpts = getWixExperimentsOptions();
  //   const experiments = new WixExperiments(wixExperimentsOpts);
  //   await experiments.load('headerAndFooter');
  //   return Object.assign(DEFAULT_EXPERIMENT_VALUE, experiments.all());
  // } catch (e) {
  //   console.error('Error to load experiments in Editorx Header and Footer');
  //   return DEFAULT_EXPERIMENT_VALUE;
  // }
};

// function getWixExperimentsOptions(): ExperimentsProps {
//   return { useNewApi: window.EDITOR_X_HF_DEBUG };
// }
