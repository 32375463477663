import { NavigationItem } from '../../../data/types';
import { createTag, mapTags } from '../../../modules/tag-creators';
import s from './Navigation.scss';

export interface SubMenuItemProps {
  item: NavigationItem;
  t(key: string): string;
}

export const SubMenuItem = ({ item, t }: SubMenuItemProps) => {
  return `
    <li class="${s.subMenuItem}">
      ${
        !item.href
          ? `<span class="${s.selectedSubMenuItem}">${item.text}</span>`
          : createTag('a', t(item.text), {
              class: `${s.link} ${s.biLink}`,
              target: item.target,
              href: item.href,
              tabindex: '0',
              'data-bi-name': item.name,
              'data-bi-row': 'second',
            })
      }
    </li>
  `;
};

export interface SubMenuProps {
  labelledBy: string;
  items?: NavigationItem[];
  t(key: string): string;
}

export const SubMenu = ({ labelledBy, items, t }: SubMenuProps) => {
  if (!items?.length) {
    return '';
  }
  return `
    <div
      class="${s.subMenuContainer} items-${items.length}"
      role="region"
      aria-labelledby="${labelledBy}"
    >
      <ul class="${s.subMenu}">
        ${mapTags(items, item => SubMenuItem({ item, t }))}
      </ul>
    </div>
  `;
};
