import s from './Navigation.scss';
import { DataHook } from '../../../modules/dataHooks';

export interface StartNowProps {
  link: string;
  t(key: string): string;
}

export const StartNow = ({ link, t }: StartNowProps): string => {
  return `
    <li data-hook="${DataHook.UserMenu}" class="${s.menuItem} ${s.userMenu}">
      <a class="${s.startNow}" href="${link}">
        ${t('editorx_menu_login')}
      </a>
    </li>
  `;
};
